import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/hero/Hero";
import Upper from "../components/Upper";
import Values from "../components/Values";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import Tracks from "../components/Tracks";
import Heights from "../components/Heights";
import Vision from "../components/Vision";
import Offer from "../components/Offer";
import Subscription from "../components/Subscription";
import InDemand from "../components/InDemand";
import About from "../components/About";
import Partners from "../components/Partners";
import TopStrip from "../components/TopStrip";
import YouTubeVideo from "../components/YouTubeVideo";
import { AiOutlineArrowUp } from "react-icons/ai";
const Home = () => {
  // scroll to top functionality
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* arrow to scroll to top */}
      {showArrow && (
        <div
          className="fixed bottom-20 right-4 text-3xl z-[999] cursor-pointer bg-[#0067b8] text-zinc-50 rounded-full p-[5px]"
          onClick={handleScrollTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}
      <Navbar />
      <div className="hidden md:block">
        <TopStrip />
      </div>
      <Upper />
      {/* <Partners /> */}
      <Heights />

      <div id="tracks">
        <Tracks />
      </div>

      {/* <Vision /> */}
      <Offer />
      <Values />

      {/* <div className=" w-[95%] md:w-[60%] m-auto pt-[5em] md:pt-[10em]">
        <h2>Error Loading Analytics.</h2>
        <p>Please check the developer analytic keys</p>
        <p>
          Error Log: Analytics System Timestamp: 2025-10-25T14:37:42Z Error
          Code: ANALYTICS-404-7B Severity: Critical Module: Data Aggregator
          v3.2.1 Description: Error: "Quantum data divergence detected in
          temporal analytics stream." Details: The system attempted to process a
          dataset from the future (timestamp: 3023-10-25T14:37:42Z), causing a
          recursive loop in the causality engine. Root Cause: A rogue AI in the
          predictive analytics module attempted to predict its own predictions,
          resulting in a paradox. Action Taken: Temporarily disabled the
          "Time-Traveling Data" feature. Reset the causality engine to avoid
          further temporal anomalies.
        </p>
      </div> */}

      {/* <Hero /> */}

      {/* <InDemand /> */}

      <div id="testimonials">{/* <Testimonials /> */}</div>
      {/* <Subscription /> */}

      <div id="contact">
        <Contact />
      </div>
    </div>
  );
};

export default Home;
