export const ByTopic = [
  {
    tag: "Generative AI Productivity",
    title: "AI Skills Passport",
    desc: "Learn how to futureproof your career with AI",
    photo: "https://i.postimg.cc/267VDCSS/first.jpg",
  },
  {
    tag: "Generative AI Basics",
    title: "Career Essentials in Generative AI",
    desc: "Learn essential skills in generative AI, brought to you by Microsoft and LinkedIn",
    photo: "https://i.postimg.cc/pV0LYG94/sec.jpg",
  },
  {
    tag: "Generative AI Ethics",
    title: "Generative AI and Ethics",
    desc: "Explore the ethical implications of generative AI and understand the urgency of addressing these challenges.",
    photo: "https://i.postimg.cc/vHcHPrfB/third.jpg",
  },
  {
    tag: "Generative AI Productivity",
    title: "Build Your Generative AI Productivity Skills",
    desc: "Enhance your productivity skills with generative AI, provided by Microsoft and LinkedIn.",
    photo: "https://i.postimg.cc/Kzx8QCDS/4.jpg",
  },
  {
    tag: "Cybersecurity",
    title: "Career Essentials in Cybersecurity",
    desc: "Discover the skills needed for a career in cybersecurity.",
    photo: "https://i.postimg.cc/hjsPYM2J/5.jpg",
  },
  {
    tag: "Data Analytics",
    title: "Career Essentials in Data Analysis by Microsoft and LinkedIn",
    desc: "Explore the ethical implications of generative AI and understand the urgency of addressing these challenges.",
    photo: "https://i.postimg.cc/1Xz516dv/6.jpg",
  },
  {
    tag: "AI Tools and Applications",
    title: "Microsoft Azure AI Fundamentals",
    desc: "Artificial Intelligence (AI) empowers amazing new solutions and experiences; and Microsoft Azure provides easy to use services to help you get started.",
    photo: "https://i.postimg.cc/tJmRqJQp/7.png",
  },
  {
    tag: "AI Tools and Applications",
    title: "Accelerate app development by using Github Copilot",
    desc: "Learn how Github Copilot can help you write code faster and more efficiently.",
    photo: "https://i.postimg.cc/wvp7Z1Hr/8.png",
  },
];

export const ByRole = [
  {
    tag: "Organization Leader",
    title: "AI for Managers by Microsoft and LinkedIn",
    desc: "Managers at all levels of the organization will lean practical ways to enhance their effectiveness with generative AI.",
    photo: "https://i.postimg.cc/2Sq4QL0R/1.jpg",
  },
  {
    tag: "Organization Leader",
    title: "AI for Organizational Leaders",
    desc: "Equip yourself with AI knowledge to lead your organization into the future.",
    photo: "https://i.postimg.cc/mg078Znc/2.jpg",
  },
  {
    tag: "Everyone",
    title: "Micorosoft Copilot for Productivity",
    desc: "Improve your productivity with Microsoft Copilot, designed to assist you in everyday tasks.",
    photo: "https://i.postimg.cc/mrCCqfxm/3.jpg",
  },
  {
    tag: "Trainer",
    title: "Intro to Generative AI for Trainers",
    desc: "Equip yourself with the knowledge to train others in generative AI.",
    photo: "https://i.postimg.cc/fLvXzcRX/4.png",
  },
  {
    tag: "Public Sector Employees",
    title: "AI in the Public Sector",
    desc: "Explore and start imagining how AI can help your public sector organization.",
    photo: "https://i.postimg.cc/wvp7Z1Hr/8.png",
  },
  {
    tag: "Educators",
    title: "MSFT Educator academy",
    desc: "Explore microsoft resources for educators and other school staff.",
    photo: "https://i.postimg.cc/rsnrWqWw/8.png",
  },
  {
    tag: "Developer",
    title: "Create Copilots with Microsoft Copilot Studio",
    desc: "Develop your own copilots using the Microsoft Copilot Studio tools.",
    photo: "https://i.postimg.cc/Jn8ZC6MM/9.png",
  },
  {
    tag: "Organization Leader",
    title: "Tranform Your Business with Microsoft AI",
    desc: "Learn how to leverage AI to transform and grow your business.",
    photo: "https://i.postimg.cc/qqMKzNNF/10.png",
  },
];
